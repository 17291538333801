import axios from 'axios';
import api from '../api';
import Vue from 'vue';

export const state = {
  accountsList: []
};

export const actions = {
  async create_guest_account ({ state, commit }, account) {
    try {
      const { data: {id}} = await axios.post(`${api.auth}/create-guest`, account);
      commit('SET_STATE', {
        key: 'isModal',
        value: false
      });
      delete account.password;
      state.accountsList.push({
        ...account,
        id,
        name: account.user,
        status: 'activ'
      });
    } catch (err) {
      err.response?.status === 409 &&
        window.alert('User existent in baza de date.');
      commit('SET_STATE', {
        key: 'isModal',
        value: false
      });
    }
  },
  async get_accounts ({ commit }) {
    try {
      const { data } = await axios.get(api.guests);
      commit('SET_STATE', {
        module: 'accounts',
        key: 'accountsList',
        value: data
      });
    } catch (err) {
      console.log(err);
    }
  },
  async change_guest_status ({ state, commit }, { id, name, status }) {
    try {
      const url = `${api.auth}/update-guest-status/${id}`;
      const newStatus = status === 'activ' ? 'inactiv' : 'activ';
      await axios.put(url, { status: newStatus, name });
      commit('UPDATE_GUEST_STATUS', { name, status: newStatus });
    } catch (err) {
      console.log(err);
    }
  },
  async delete_guest_account ({ commit }, { id, clbk }) {
    try {
      const url = `${api.deleteGuestAccount}/${id}`;
      await axios.delete(url);
      commit('DELETE_ACCOUNT', id);
      clbk();
    } catch (err) {
      console.log(err);
    }
  }
};

export const mutations = {
  UPDATE_GUEST_STATUS (state, { name, status }) {
    state.accountsList.forEach((account, index) => {
      account.name === name &&
        Vue.set(state.accountsList, index, {
          ...account,
          status
        });
    });
  },
  DELETE_ACCOUNT (state, id) {
    state.accountsList.forEach((obj, index) => {
      obj.id === id && state.accountsList.splice(index, 1);
    });
  }
};
