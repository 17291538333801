<template>
  <section
    v-if="$store.state.isModal"
    class="base-modal fixed wh100 left0 top0 flex-center z99"
    @click="close"
  >
    <slot />
  </section>
</template>

<script>
  export default {
    name: 'BaseModal',
    methods: {
      close () {
        this.$store.commit('SET_STATE', { key: 'isModal', value: false });
      }
    }
  };
</script>

<style lang="scss">
  .base-modal {
    background-color: rgba(0, 0, 0, 0.6);
  }
</style>
