import axios from 'axios';
import router from '@/router';
import api from '../api';

export const state = {
  user: {},
  loginErr: false
};

export const actions = {
  async login ({ commit }, form) {
    try {
      commit('SET_STATE', { module: 'auth', key: 'loginErr', value: false });
      const { data } = await axios.post('/api/auth/login', form);
      commit('SET_STATE', { module: 'auth', key: 'user', value: data });
      await router.push('/objects');
    } catch (err) {
      err.response?.status === 401 &&
        commit('SET_STATE', { module: 'auth', key: 'loginErr', value: true });
      err.response?.status === 402 && window.alert('Contul dvs. a expirat');
    }
  },
  async check_login ({ commit }, { next, role }) {
    try {
      const { data } = await axios.get(`${api.auth}/check-login`);
      data.name &&
        commit('SET_STATE', { module: 'auth', key: 'user', value: data });
      data.name
        ? role && role !== data.role
          ? next('/login')
          : next()
        : next('/login');
    } catch (e) {
      console.log(e);
    }
  },
  async logout ({ commit }) {
    try {
      await axios.delete(`${api.auth}/logout`);
      await router.push('/login');
    } catch (err) {
      window.alert('Error sign out.');
    }
  }
};
