<template>
  <section class="lista-ansambluri bg-white" @click.stop>
    <h6>
      <b>Lista ansambluri</b>
    </h6>
    <ul>
      <li
        cy="test-ansamblu"
        class="flex flex-between align-center"
        v-for="({ nume, id }, index) in list"
        :key="index"
      >
        <label
          :for="id" class="pointer"
          >
          {{ nume }}
        </label>
        <input
          v-if="nume"
          @change="setValue({ nume, id })"
          v-model="value"
          :value="nume"
          class="pointer"
          :id="id"
          type="radio"
        />
      </li>
    </ul>
    <button
      cy="save-button"
      :disabled="!selectedValue"
      class="button is-success mt-4"
      @click="save"
    >
      SAVE
    </button>
  </section>
</template>

<script>
  export default {
    name: 'ListaAnsambluri',
    data () {
      return {
        selectedValue: false,
        value: undefined
      };
    },
    props: {
      list: Array,
      ansamblu: {
        type: Object,
        default: () => ({})
      }
    },
    created () {
      this.value = this.ansamblu.nume;
    },
    methods: {
      setValue (value) {
        this.selectedValue = value;
      },
      save () {
        this.$emit('link-ansamblu', this.selectedValue);
      }
    }
  };
</script>

<style lang="scss">
  .lista-ansambluri {
    padding: 24px 28px;
    border-radius: 4px;
    h6 {
      font-size: 1.4rem;
      margin-bottom: 18px;
    }
    ul {
      max-height: 212px;
      overflow-y: scroll;
      padding: 0 38px;
    }
    li {
      min-width: 300px;
      font-size: 1.2rem;
    }
    label:hover {
      text-decoration: underline;
    }
    input {
      transform: scale(1.5);
    }
    button[disabled] {
      opacity: 0.6;
    }
  }
</style>
