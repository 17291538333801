<template>
  <validation-provider
    class="base-multiselect"
    :cy="name"
    :rules="rules"
    v-slot="{ errors }"
  >
    <label :for="name">{{ label || name }}</label>
    <Multiselect
      select-label="Click to select"
      :deselect-label="allowEmpty ? 'Click to remove' : ''"
      :allow-empty="allowEmpty"
      :id="name"
      :name="name"
      v-model="data"
      :placeholder="placeholder"
      @input="input"
      @tag="addTag"
      :options="list"
      :multiple="taggable"
      :taggable="multiple"
      :label="sortBy"
      :track-by="sortBy"
      tag-placeholder="Add this as new tag"
      :close-on-select="closeOnSelect"
      :clear-on-select="clearOnSelect"
      :preserve-search="preserveSearch"
      :hide-selected="hideSelected"
    />
    <span class="has-text-danger" v-if="errors[0]">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import Multiselect from 'vue-multiselect';
  import 'vue-multiselect/dist/vue-multiselect.min.css';
  export default {
    name: 'BaseMultiSelect',
    components: {
      ValidationProvider,
      Multiselect
    },
    props: {
      hideSelected: Boolean,
      preserveSearch: Boolean,
      closeOnSelect: Boolean,
      clearOnSelect: Boolean,
      name: String,
      placeholder: String,
      rules: String,
      sortBy: String,
      list: Array,
      multiple: Boolean,
      taggable: Boolean,
      allowEmpty: {
        type: Boolean,
        default: true
      },
      label: String,
      type: String,
      value: String | Array
    },
    data () {
      return {
        data: []
      };
    },
    mounted () {
      this.value && (this.data = this.value);
    },
    methods: {
      input (value) {
        this.$emit('input', { name: this.name, value, type: this.type });
      },
      addTag (tag) {
        this.data.push(tag);
        this.$emit('input', {
          name: this.name,
          value: this.data
        });
      }
    },
    watch: {
      value: function (val) {
        this.data = val;
      }
    }
  };
</script>
<style lang="scss">
  .base-multiselect {
    display: block;
    label,
    input {
      display: block;
    }
    label {
      margin-bottom: 4px;
    }
    .multiselect__tags {
      padding: 6px 40px 0 8px;
    }

    .multiselect__single {
      padding-top: 4px;
    }
    .multiselect__placeholder {
      font-size: 16px;
      padding-top: 1px;
    }
  }
</style>
