import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { extend } from 'vee-validate';
import { required, numeric, email } from 'vee-validate/dist/rules';
import './styles/base.scss';
import 'vue-datetime/dist/vue-datetime.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BaseForm from './components/BaseForm';
import BaseInput from './components/BaseInput';
import BaseInputText from './components/BaseInputText';
import BaseModal from './components/BaseModal';
import BaseFileInput from './components/BaseFileInput';
import BaseCheckboxInput from './components/BaseCheckboxInput';
import BaseConfirmationModal from './components/BaseConfirmationModal';
import BaseLoaderSpinner from './components/BaseLoaderSpinner';
import BaseDropdown from './components/BaseDropdown';
import BaseMultiSelect from './components/BaseMultiSelect';
import BaseDatePicker from './components/BaseDatePicker';
import {
  faCalendarAlt,
  faFileImport,
  faWindowClose,
  faChevronLeft,
  faChevronRight,
  faSpinner,
  faSearch,
  faSignOutAlt,
  faHome,
  faUserCog,
  faTrashAlt,
  faTrashRestore,
  faFilePdf,
  faCheck,
  faInfoCircle,
  faLayerGroup,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faCalendarAlt,
  faFileImport,
  faWindowClose,
  faChevronLeft,
  faChevronRight,
  faSpinner,
  faSearch,
  faSignOutAlt,
  faHome,
  faUserCog,
  faTrashAlt,
  faTrashRestore,
  faFilePdf,
  faCheck,
  faInfoCircle,
  faLayerGroup,
  faEye,
  faEyeSlash
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('BaseForm', BaseForm);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseInputText', BaseInputText);
Vue.component('BaseFileInput', BaseFileInput);
Vue.component('BaseCheckboxInput', BaseCheckboxInput);
Vue.component('BaseModal', BaseModal);
Vue.component('BaseConfirmationModal', BaseConfirmationModal);
Vue.component('BaseLoaderSpinner', BaseLoaderSpinner);
Vue.component('BaseDropdown', BaseDropdown);
Vue.component('BaseMultiSelect', BaseMultiSelect);
Vue.component('BaseDatePicker', BaseDatePicker);

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

extend('required', {
  ...required,
  message: 'Câmp obligatoriu.'
});
extend('email', {
  ...email,
  message: 'Adresa nu este în format email.'
});
extend('numeric', {
  ...numeric,
  message: 'Câmp obligatoriu.'
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
