import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Objects from '../views/Objects.vue';
import EditObject from '../views/EditObject.vue';
import Admin from '@/views/Admin';
import GuestAccounts from '@/views/GuestAccounts';
import Ansamblu from '@/views/Ansamblu';
import PageNotFound from '@/views/404';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/objects'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/objects',
    name: 'Objects',
    meta: { auth: true },
    component: Objects
  },
  {
    path: '/ansamblu/:id',
    name: 'Ansamblu',
    meta: { auth: true },
    component: Ansamblu
  },
  {
    path: '/edit-object/:id',
    name: 'EditObject',
    meta: { auth: true },
    component: EditObject
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    children: [
      {
        path: '',
        redirect: 'guest-accounts'
      },
      {
        path: 'guest-accounts',
        name: 'Guest',
        meta: { auth: true, role: 'archivist' },
        component: GuestAccounts
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async ({ name, meta: { auth, role } }, from, next) => {
  const user = store.state.auth.user;
  if (auth && !user.name) {
    await store.dispatch('check_login', { next, role });
  } else {
    role && role !== user.role ? next('/login') : next();
  }
});

export default router;
