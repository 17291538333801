const api = {
  objects: '/api/objects',
  object: '/api/object',
  ansamblu: '/api/ansamblu',
  auth: '/api/auth',
  pdf: '/api/objects/get-pdf',
  guests: '/api/auth/get-guests',
  deleteGuestAccount: '/api/auth/delete-guest',
  deleteObjectAnsamblu: '/api/object/remove-from-ansamblu'
};

export default api;
