<template>
  <div class="objects-page main-page relative">
    <!-- MODAL QR CODE PRINT -->
    <transition name="modal-fade">
      <BaseModal>
        <ObjectsCountingInfo
          v-if="modalComponentName === 'objectsCount'"
          :total="objectsCount"
        />
        <div
          v-if="modalComponentName === 'qrPreview'"
          class="qr-preview fixed flex-center"
          @click.stop
        >
          <font-awesome-icon
            class="no-print absolute close pointer"
            @click="$store.state.isModal = false"
            icon="window-close"
            size="2x"
          />
          <span>{{ codInventar }}</span>
          <figure>
            <img :src="qrPreviewUrl" alt="" />
          </figure>
          <button
            class="no-print button is-primary has-text-white absolute"
            @click="printQr"
          >
            Print
          </button>
        </div>
        <div
          v-if="modalComponentName === 'newObject'"
          class="modal-materiale modal is-active"
          @click.stop
        >
          <header class="modal-card-head w100">
            <h6 class="modal-card-title flex-center">
              <b>Selecteaza material</b>
            </h6>
            <button class="delete" @click="closeModal"></button>
          </header>
          <div class="options-wrapper bg-white flex w100">
            <!-- Obiect / Ansamblu -->
            <div
              class="select-category flex-center"
              :class="{ 'slide-left': isObiectIndividual }"
            >
              <div class="text-left">
                <label cy="obiect-individual" for="obiect-individual">
                  <input
                    type="radio"
                    id="obiect-individual"
                    name="categorie"
                    @change="handleMaterialCategory"
                    value="obiectIndividual"
                  />
                  Obiect individual
                </label>
                <label cy="ansamblu" for="ansamblu">
                  <input
                    type="radio"
                    name="categorie"
                    value="ansamblu"
                    @change="handleMaterialCategory"
                    id="ansamblu"
                  />
                  Ansamblu
                </label>
              </div>
            </div>
            <!-- Materiale -->
            <div
              class="select-material flex-center relative"
              :class="{ 'slide-left': isObiectIndividual }"
            >
              <button class="absolute" @click="goBackToOptions">
                <font-awesome-icon class="icon" size="2x" icon="chevron-left" />
              </button>
              <ul
                class="flex-center flex-wrap"
              >
                <li
                  class="text-left"
                  v-for="(item, key) in materiale"
                  :key="key"
                >
                  <label
                    :cy="`material-${item}`"
                    :for="key"
                  >
                    <input
                      v-model="checked"
                      type="radio"
                      name="material"
                      :value="key"
                      @change="selectMaterial"
                      :id="key"
                    />
                    {{ item }}
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <footer class="modal-card-foot w100">
            <button
              cy="create-object"
              class="button is-success m-left-auto"
              :disabled="
                !materialCategoryName ||
                materialCategoryName === 'obiectIndividual'
              "
              @click="handleSubmitNewObject"
            >
              Save
            </button>
            <button class="button" @click="closeModal">Cancel</button>
          </footer>
        </div>
      </BaseModal>
    </transition>

    <h1 class="title">Registrul obiectelor</h1>
    <button
      cy="new-object-btn"
      class="button absolute is-primary has-text-white"
      @click="createNewObject()"
    >
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M12 12H4M12 20V12V20ZM12 12V4V12ZM12 12H20H12Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </button>

    <div class="flex is-justify-content-space-between m-0">
      <!-- SEARCH BOX -->
      <div class="search-box flex">
        <input
          v-model="searchValue"
          @keyup.enter="handleSearch"
          placeholder="cauta obiect"
        />
        <font-awesome-icon
          @click="handleSearch"
          class="search-icon fixed z99"
          icon="search"
        />
        <i
          class="pointer reset-search"
          :class="{ 'is-search-active': searchValue }"
          @click="handleResetSearch"
        >
          <svg width="36" height="36" viewBox="0 0 36 36">
            <path
              d="M19.41 18L27.7 9.71C27.8638 9.5187 27.9494 9.27262 27.9397 9.02095C27.93 8.76927 27.8256 8.53053 27.6475 8.35244C27.4694 8.17434 27.2307 8.07001 26.979 8.06029C26.7274 8.05057 26.4813 8.13618 26.29 8.3L18 16.59L9.70997 8.29C9.52167 8.1017 9.26627 7.99591 8.99997 7.99591C8.73367 7.99591 8.47828 8.1017 8.28997 8.29C8.10167 8.47831 7.99588 8.7337 7.99588 9C7.99588 9.2663 8.10167 9.5217 8.28997 9.71L16.59 18L8.28997 26.29C8.18529 26.3796 8.10027 26.49 8.04025 26.614C7.98022 26.7381 7.94649 26.8732 7.94117 27.0109C7.93586 27.1487 7.95906 27.286 8.00934 27.4143C8.05961 27.5427 8.13587 27.6592 8.23332 27.7567C8.33078 27.8541 8.44733 27.9304 8.57565 27.9806C8.70398 28.0309 8.84131 28.0541 8.97903 28.0488C9.11675 28.0435 9.25188 28.0098 9.37594 27.9497C9.50001 27.8897 9.61033 27.8047 9.69997 27.7L18 19.41L26.29 27.7C26.4813 27.8638 26.7274 27.9494 26.979 27.9397C27.2307 27.93 27.4694 27.8257 27.6475 27.6476C27.8256 27.4695 27.93 27.2307 27.9397 26.9791C27.9494 26.7274 27.8638 26.4813 27.7 26.29L19.41 18Z"
              fill="black"
            />
          </svg>
        </i>
      </div>

      <div class="flex align-center">
        <i class="info-icon pointer" @click="showCountingInfo">
          <font-awesome-icon cy="info-circle-icon" icon="info-circle" />
        </i>
        <!-- Objects FILTERS -->
        <BaseDropdown
          class="objects-filters"
          :options="objectsFilters"
          :default-value="viewFilter"
          @handleDropdownItem="handleViewObjects"
        />
      </div>
    </div>

    <div class="objects-table">
      <!-- TABLE HEADER -->
      <div class="table-header">
        <ul class="flex">
          <li
            :class="[
              key && key.replace(/_/g, '-'),
              sortBy.includes(key) ? 'pointer' : '',
              sortByKey === key && sortOrder === 'DESC' ? 'sort-desc' : '',
            ]"
            @click="handleSortBy(key)"
            v-for="(item, key) in tableHeader"
            :key="key"
          >
            <b class="pointer">{{ item }}</b>
          </li>
        </ul>
      </div>
      <!-- TABLE BODY -->
      <div cy="table-body" class="table-body">
        <!-- BASE LOADER SPINNER -->
        <div
          v-if="objects && !objects.length"
          class="fetching-indicator flex-center h100"
        >
          <BaseLoaderSpinner />
        </div>
        <ul
          cy="object-details"
          class="flex relative"
          v-for="(
            {
              _id,
              cod_inventar,
              nume,
              uz,
              provenienta,
              status,
              proces_restaurare,
              ansamblu,
              cod_attr,
            },
            index
          ) in objects"
          :key="index"
        >
          <li class="cod-inventar" cy="cod-inventar">
            <router-link
              :to="`/${
                cod_attr && cod_attr.type === 'A' ? 'ansamblu' : 'edit-object'
              }/${_id}`"
            >
              {{ cod_inventar }}
            </router-link>
          </li>
          <li class="nume">
            <span>
              {{ nume }}
            </span>
          </li>
          <li class="uz">
            <span>{{ uz }}</span>
          </li>
          <li class="provenienta">
            <span>{{ provenienta }}</span>
          </li>
          <li
            :class="`proces-restaurare ${
              proces_restaurare &&
              proces_restaurare.replace(/ /g, '-').toLowerCase()
            }`"
          >
            <span v-show="proces_restaurare" class="multiselect__single">{{
              proces_restaurare
            }}</span>
          </li>
          <li class="status">
            <span>{{ status }}</span>
          </li>
          <li class="qr-code pointer" @click="qrPreview({ _id, cod_inventar })">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.25 0.75H0.75V8.25H3.25V3.25H8.25V0.75ZM0.75 23.25V15.75H3.25V20.75H8.25V23.25H0.75ZM15.75 0.75V3.25H20.75V8.25H23.25V0.75H15.75ZM20.75 15.75H23.25V23.25H15.75V20.75H20.75V15.75ZM5.75 5.75H10.75V10.75H5.75V5.75ZM5.75 13.25H10.75V18.25H5.75V13.25ZM18.25 5.75H13.25V10.75H18.25V5.75ZM13.25 13.25H18.25V18.25H13.25V13.25Z"
                fill="black"
              />
            </svg>
          </li>
          <li v-if="ansamblu" class="ansamblu-icon absolute right0 ">
            <font-awesome-icon icon="layer-group" />
          </li>
          <li v-if="cod_attr.type === 'A'" class="ansamblu-icon absolute right0">
              <span class="mr-1">A</span>
          </li>
        </ul>
      </div>
      <!-- PAGINATION -->
      <div v-show="totalPages > 0" class="pagination flex-center">
        <button :disabled="+currentPage === 1" @click="goToPage('first')">
          <font-awesome-icon class="icon" size="2x" icon="chevron-left" />
        </button>
        <button :disabled="+currentPage === 1" @click="goToPage('prev')">
          <font-awesome-icon class="icon" icon="chevron-left" />
        </button>
        <span class="page-number">{{ currentPage }} / {{ totalPages }}</span>
        <button
          :disabled="+currentPage === +totalPages"
          @click="goToPage('next')"
        >
          <font-awesome-icon class="icon" icon="chevron-right" />
        </button>
        <button
          :disabled="+currentPage === +totalPages"
          @click="goToPage('last')"
        >
          <font-awesome-icon class="icon" size="2x" icon="chevron-right" />
        </button>
        <span>total: {{ count }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { format } from 'date-fns';
  import { mapState } from 'vuex';
  import ObjectsCountingInfo from '@/components/BaseObjectsCountingInfo';

  export default {
    name: 'Objects',
    data () {
      return {
        modalComponentName: '',
        sortBy: ['nume'],
        sortByKey: undefined,
        sortOrder: 'ASC',
        objectsFilters: [
          {label: 'Valide', value: 'effective'}, 
          {label: 'Toate', value:'all'}, 
          {label:'Sterse', value:'deleted'}
        ],
        viewFilter: 'Valide',
        tableHeader: {
          cod_inventar: 'Cod Inventar',
          nume: 'Nume Obiect',
          uz: 'Categorie Uz',
          provenienta: 'Provenienta',
          proces_restaurare: 'Restaurare',
          status: 'Status',
          'qr-code': 'QR'
        },
        format,
        codInventar: undefined,
        qrPreviewUrl: undefined,
        type: [],
        materialeFormHasError: false,
        currentPage: this.$router.currentRoute.query.page || 1,
        searchValue: undefined,
        materiale: {
          M: 'Metal',
          L: 'Lemn',
          P: 'Piatra',
          C: 'Ceramica',
          T: 'Textil',
          O: 'Os',
          S: 'Sticla',
          U: 'Ou',
          E: 'Piele'
        },
        isCategorySelection: true,
        isObiectIndividual: false,
        materialCategoryName: undefined,
        checked: false
      };
    },
    components: {
      ObjectsCountingInfo
    },
    computed: {
      ...mapState({
        objects: (state) => state.objects.objects,
        totalPages: (state) => state.objects.totalPages,
        objectsCount: (state) => state.objects.objectsCount,
        count: (state) => state.objects.totalObjects
      }),
      queryParams () {
        return this.$router.currentRoute.query;
      }
    },
    created () {
      if (!this.$router.currentRoute.query.view) {
        this.$router.replace({ query: { view: 'effective' } });
      }
      this.$store.dispatch('get_objects', this.queryParams);
    },
    mounted () {
      this.searchValue = this.$router.currentRoute.query.search;
      const view = this.$router.currentRoute.query.view;
      // dorim sa traducem effective in RO, deci cand avem in query view "effective" trebuie sa aplicam ternar
      this.viewFilter = view 
        ? this.objectsFilters.filter(o => o.value === view).map(o => o.label)[0]
        : 'Valide';
    },
    methods: {
      showCountingInfo () {
        this.modalComponentName = 'objectsCount';
        this.$store.commit('SET_STATE', { key: 'isModal', value: true });
      },
      handleSortBy (sortby) {
        this.sortByKey = sortby;
        let query = '';
        this.sortOrder =
          this.$router.currentRoute.query.order === 'ASC' ? 'DESC' : 'ASC';
        query = {
          ...this.$router.currentRoute.query,
          sortby,
          order: this.sortOrder
        };
        this.$router.replace({ query });
        this.$store.dispatch('get_objects', query);
      },
      handleViewObjects ({ value: view }) {
        const currentView = this.$router.currentRoute.query.view;
        if (currentView !== view) {
          let query = '';
          query = { ...this.$router.currentRoute.query, view };
          this.$router.replace({ query });
          this.$store.dispatch('get_objects', query);
        }
      },
      goBackToOptions () {
        this.isObiectIndividual = false;
        this.checked = false;
      },
      handleMaterialCategory ({ target: { value } }) {
        this.materialCategoryName = value;
        this.isObiectIndividual = value === 'obiectIndividual';
      },
      handleResetSearch () {
        this.searchValue = '';
        this.$store.dispatch('get_objects', this.queryParams);
        this.$router.replace({ query: null });
        this.currentPage = 1;
      },
      handleSearch () {
        if (this.searchValue.length > 2) {
          this.$store.dispatch('search_objects', this.searchValue);
          this.$router
            .replace({ query: { search: this.searchValue } })
            .catch(() => {});
          this.currentPage = 1;
        }
      },
      goToPage (page) {
        this.$store.commit('SET_STATE', {
          module: 'objects',
          key: 'objects',
          value: []
        });
        let query = '';
        switch (page) {
          case 'first':
            this.currentPage = 1;
            query = {
              ...this.$router.currentRoute.query,
              page: this.currentPage
            };
            this.$router.replace({ query });
            this.$store.dispatch('get_objects', query);
            break;
          case 'prev':
            this.currentPage = +this.currentPage - 1;
            query = { ...this.$router.currentRoute.query };
            this.currentPage > 1
              ? (query.page = this.currentPage.toString())
              : delete query.page;
            this.$router.replace({ query });
            this.$store.dispatch(
              'get_objects',
              this.$router.currentRoute.query
            );
            break;
          case 'next':
            this.currentPage = +this.currentPage + 1;
            query = {
              ...this.$router.currentRoute.query,
              page: this.currentPage
            };
            this.$router.replace({ query });
            this.$store.dispatch(
              'get_objects',
              this.$router.currentRoute.query
            );
            break;
          case 'last':
            this.currentPage = this.totalPages;
            query = {
              ...this.$router.currentRoute.query,
              page: this.currentPage
            };
            this.$router.replace({ query });
            this.$store.dispatch(
              'get_objects',
              this.$router.currentRoute.query
            );
            break;
        }
      },
      closeModal (ev) {
        ev.preventDefault();
        this.$store.commit('SET_STATE', { key: 'isModal', value: false });
      },
      handleSubmitNewObject () {
        if (this.materialCategoryName === 'ansamblu') {
          this.materialCategoryName = 'A';
        }
        this.materialeFormHasError = !this.materialCategoryName;
        this.materialCategoryName &&
          this.$store.dispatch('generate_object', this.materialCategoryName) &&
          (this.materialCategoryName = undefined);
      },
      createNewObject () {
        this.modalComponentName = 'newObject';
        this.$store.dispatch('verify_new_object_creation');
      },
      printQr () {
        window.print();
      },
      qrPreview ({ _id, cod_inventar }) {
        this.modalComponentName = 'qrPreview';
        this.codInventar = cod_inventar;
        this.qrPreviewUrl = `/imgs/${_id}/qr.png`;
        this.$store.commit('SET_STATE', { key: 'isModal', value: true });
      },
      selectMaterial (ev) {
        this.materialCategoryName = ev.target.value;
      }
    }
  };
</script>

<style lang="scss">
  .objects-page {
    max-width: 1160px;
    margin: 0 auto;

    .ansamblu-icon {
      height: 100%;
      right: -3px;
      color: cadetblue;
    }
    .objects-filters {
      width: 100px;
      margin-bottom: 0 !important;
      margin-top: 10px;

      span {
        padding: 2px 4px;
        border: none;
      }

      ul {
        border: 1px solid lightgray;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

        li {
          padding: 4px 12px;
        }
      }
    }
    .info-icon {
      display: inline-block;
      transform: translateY(4px) scale(1.2);
      svg {
        color: grey;
      }
      &:hover svg {
        color: #2abb2a;
      }
    }

    .search-box {
      width: 340px;
      justify-content: flex-start;
      position: relative;

      input {
        width: 100%;
        height: 40px;
        padding-left: 16px;
        border-radius: 4px;
        border: 1px solid #a8a8a8;
        outline: none;
        font-size: 16px;
        font-weight: 300;
      }

      .search-icon {
        position: absolute;
        right: 46px;
        top: 10px;
        font-size: 18px;
        color: #848484;
        cursor: pointer;
        z-index: 0;
      }

      .reset-search {
        opacity: 0.3;
        transform: translate(4px, 1px);
      }

      .is-search-active {
        opacity: 1;
        pointer-events: auto;
      }
    }

    & > button {
      right: 20px;
      top: 42px;
    }

    .qr-preview {
      height: 240px;
      width: 600px;
      padding: 16px;
      margin: 4px;
      background-color: white;
      border-radius: 10px;

      & > span {
        margin-right: 12px;
        font-size: 42px;
        display: inline-block;
      }

      figure {
        height: 100%;

        img {
          height: 100%;
          align-self: center;
        }
      }

      button.absolute {
        bottom: -54px;
        right: 0;
      }

      .close {
        right: -36px;
        top: -36px;

        path {
          fill: white;
        }
      }
    }

    div.objects-table {
      padding: 8px 0;
      min-width: 1000px;

      .table-header,
      .table-body {
        text-transform: capitalize;
      }

      .fetching-indicator {
        height: 300px;
      }

      .table-header {
        padding: 4px 0;
        background-color: #f1f1f1;
        border-radius: 4px;

        li {
          font-size: 1rem;
        }

        .nume:after {
          content: "\2193";
          color: #00d1b2;
          position: absolute;
          left: 110px;
          transition: ease all 0.4s;
        }
      }

      .sort-desc:after {
        transform: rotate(180deg);
      }

      .table-body {
        min-height: calc(43px * 10);
        border-bottom: 1px solid lightgrey;

        ul:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }

        li {
          display: flex;
          align-items: center;
        }
      }

      ul {
        padding: 0 8px;
      }

      li {
        padding: 8px 0;
        text-align: left;
        font-size: 1rem;
        //border: 1px solid red;
      }

      .cod-inventar {
        width: 145px;
      }

      .nume {
        width: 240px;
        position: relative;
      }

      .uz {
        width: 240px;
      }

      .provenienta {
        width: 140px;
      }

      .proces-restaurare {
        width: 160px;
      }

      .status {
        width: 150px;
      }

      .qr-code {
        width: 50px;
        text-align: center;
        justify-content: center;
      }
    }

    .modal-materiale {
      width: 460px;
      margin: 0 auto;
      .options-wrapper {
        label,
        input {
          cursor: pointer;
        }
        label {
          display: block;
          margin-bottom: 12px;
          &:hover {
            text-decoration: underline;
          }
        }
        input {
          margin-right: 8px;
          transform: scale(1.5);
        }
      }
      .select-category,
      .select-material {
        padding: 48px 48px 24px;
        transition: transform 150ms ease-in-out;
      }
      .select-category {
        width: 100%;
        flex: 0 0 auto;
      }
      .select-material {
        width: 100%;
        flex: 0 0 auto;
        ul {
          transform: translateX(28px);
        }
        li {
          width: 30%;
        }
        button.absolute {
          top: 46%;
          left: 16px;
          background: transparent;
        }
      }
    }
    .slide-left {
      transform: translateX(-100%);
    }

    .pagination {
      margin-top: 22px;

      button {
        background: transparent;
      }

      button[disabled] {
        svg {
          opacity: 0.3;
          cursor: default;
        }

        cursor: default;
      }

      .icon {
        color: #00c4a7;
        cursor: pointer;
      }

      .icon:first-child {
        margin-right: 2px;
      }

      .icon:last-child {
        margin-left: 6px;
      }

      span.page-number {
        display: inline-block;
        margin: 0 24px;
        font-size: 1.2rem;
      }
    }

    .multiselect__single {
      background: transparent;
      margin-bottom: 0;
      padding-left: 0 !important;
    }
  }

  @media print {
    .no-print {
      display: none;
      opacity: 0;
    }
    .qr-preview {
      height: 24mm !important;
      width: 60mm !important;
      padding: 8px !important;
      border: 1px solid lightgrey;

      & > span {
        font-size: 16px !important;
      }
    }
  }
</style>
