<template>
  <section class="guest-accounts">
    <transition name="modal-fade">
      <BaseConfirmationModal v-if="isConfirmDeleteAccount">
        <div class="notify-modal-ok confirm-delete-box text-center">
          <p>Sunteti sigur ca doriti sa stergeti acest cont?</p>
          <button
            class="button is-success"
            cy="confirm-delete"
            @click.stop="deleteAccount"
          >
            DA
          </button>
          <button class="button is-light" @click.stop="cancelDelete">NU</button>
        </div>
      </BaseConfirmationModal>
    </transition>

    <transition name="modal-fade">
      <BaseModal>
        <div @click.stop>
          <BaseForm @submit="handleSubmit">
            <h6 class="text-center">Crează cont guest</h6>
            <BaseInput
              name="email"
              label="adresa email"
              @input="handleInput"
              rules="required|email"
            />
            <BaseInput
              name="password"
              label="parola"
              @input="handleInput"
              disabled
              :value="password"
            />
            <BaseMultiSelect
              class="mt-3"
              name="expiry_date"
              @input="handleMultiSelect"
              :list="valability.map((o) => o.name)"
              label="valabilitate"
              :allow-empty="false"
              :value="valability[0].name"
            />
            <button
              cy="submit-new-account"
              class="button is-primary btn-center"
            >
              SAVE
            </button>
          </BaseForm>
        </div>
      </BaseModal>
    </transition>

    <header class="flex flex-between">
      <h1>Conturi guest</h1>
      <button
        class="button is-primary has-text-white"
        cy="add-guest"
        @click="addGuest"
      >
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M12 12H4M12 20V12V20ZM12 12V4V12ZM12 12H20H12Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </button>
    </header>
    <div class="guest-accounts-table">
      <div class="table-header">
        <ul class="flex">
          <li :class="key" v-for="(item, key) in tableHeader" :key="key">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="table-body" cy="guest-accounts-table">
        <ul
          class="flex"
          v-for="({ id, name, status, expiry_date }, index) in accounts"
          :key="name"
        >
          <li class="cont flex align-center">
            <img src="../assets/images/ic_round-account-circle.svg" />
            {{ name }}
          </li>
          <li class="valid">
            {{ format(new Date(expiry_date), "MMM dd, yyyy") }}
          </li>
          <li class="status" cy="guest-status">
            {{ status }}
          </li>
          <li class="actiuni relative flex align-center">
            <img
              class="pointer"
              cy="show-guest-options"
              @click="showAccountsActions(index)"
              :src="
                require(index === selectedItemIndex
                  ? '../assets/images/close-icon.svg'
                  : '../assets/images/options.svg')
              "
            />
            <div
              v-show="index === selectedItemIndex"
              class="account-options absolute"
            >
              <div
                class="pointer"
                cy="disable-guest"
                @click="changeStatus({ id, name, status, index })"
              >
                <img
                  class="dezactiveaza"
                  src="../assets/images/carbon_checkbox-checked.svg"
                />
                <span>{{
                  status === "activ" ? "dezactivează" : "reactivează"
                }}</span>
              </div>
              <div
                class="pointer"
                cy="delete-guest"
                @click="confirmDeleteAccount(index)"
              >
                <img
                  class="sterge"
                  src="../assets/images/fluent_delete-48-regular.svg"
                />
                <span>șterge</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
  import { format } from 'date-fns';
  export default {
    name: 'GuestAccounts',
    data () {
      return {
        tableHeader: {
          cont: 'cont',
          valid: 'valid pana la',
          status: 'status',
          actiuni: 'actiuni'
        },
        selectedItemIndex: undefined,
        format,
        user: undefined,
        expiry_date: 1,
        valability: [
          {
            name: '1 zi',
            days: 1
          },
          {
            name: '7 zile',
            days: 7
          },
          {
            name: '30 zile',
            days: 30
          },
          {
            name: '6 luni',
            days: 180
          }
        ],
        isConfirmDeleteAccount: false,
        password: undefined
      };
    },
    created () {
      this.$store.dispatch('get_accounts');
    },
    computed: {
      accounts () {
        return this.$store.state.accounts.accountsList;
      }
    },
    methods: {
      generatePassword () {
        this.password = Math.random().toString(36).slice(-8);
      },
      addGuest () {
        this.generatePassword();
        this.$store.commit('SET_STATE', { key: 'isModal', value: true });
      },
      handleSubmit () {
        const account = {
          user: this.email,
          password: this.password,
          expiry_date: new Date().setDate(
            new Date().getDate() + this.expiry_date
          )
        };
        this.$store.dispatch('create_guest_account', account);
      },
      handleInput ({ name, value }) {
        this[name] = value;
      },
      handleMultiSelect ({ name, value }) {
        this[name] = this.valability.filter((o) => o.name === value)[0].days;
      },
      showAccountsActions (index) {
        if (this.selectedItemIndex === index) {
          this.selectedItemIndex = undefined;
        } else {
          this.selectedItemIndex = index;
        }
      },
      changeStatus (user) {
        if (this.selectedItemIndex === user.index) {
          this.selectedItemIndex = undefined;
        }
        this.$store.dispatch('change_guest_status', user);
      },
      confirmDeleteAccount (index) {
        this.isConfirmDeleteAccount = true;
      },
      deleteAccount () {
        const clbk = () => {
          this.isConfirmDeleteAccount = false;
          this.selectedItemIndex = undefined;
        };
        const id = this.accounts[this.selectedItemIndex].id;
        this.$store.dispatch('delete_guest_account', {
          id,
          clbk
        });
      },
      cancelDelete () {
        this.isConfirmDeleteAccount = false;
        this.selectedItemIndex = undefined;
      }
    }
  };
</script>

<style lang="scss">
  @import "../styles/vars";
  .guest-accounts {
    width: 800px;
    margin-left: 370px;
    padding-top: 100px;
    form {
      width: 500px;
      max-width: 500px !important;
      background-color: white;
      padding: 24px;
      border-radius: 4px;
      h6 {
        font-size: 1.4rem;
      }
      .required:before {
        display: none !important;
      }
      input[disabled] {
        color: black;
      }
    }
    h1 {
      text-align: left;
      font-size: 28px;
      color: #3c4ac7;
      margin-bottom: 24px;
    }
    .guest-accounts-table {
      text-align: left;
      .table-header {
        background: #dddddd;
        border-radius: 4px;
        text-align: left;
        padding: 4px 16px;
        li {
          font-size: 1rem;
          font-weight: bold;
        }
      }
      .table-body {
        background: white;
        text-align: left;
        padding: 4px 16px;
        ul {
          padding: 4px 0;
          align-items: center;
          img {
            width: 37px;
          }
        }
        .account-options {
          width: 140px;
          background-color: white;
          border-radius: 4px;
          position: absolute;
          right: 96px;
          top: 0;
          z-index: 90;
          & > div {
            display: flex;
            align-items: center;
          }
          div:first-child {
            border-bottom: 1px solid lightgray;
          }
          border: 1px solid lightgray;
        }
      }

      .cont {
        width: 400px;
      }
      .valid {
        width: 200px;
      }
      .status {
        width: 100px;
      }
      .actiuni {
        width: 100px;
        div.pointer:hover {
          background-color: $light-gray;
        }
        .sterge {
          transform: scale(0.8);
        }
      }
    }
  }
</style>
