<template>
  <section
    class="base-confirmation-modal fixed wh100 left0 top0 flex-center z99"
  >
    <slot />
  </section>
</template>

<script>
  export default {
    name: 'BaseConfirmationModal'
  };
</script>

<style lang="scss">
  .base-confirmation-modal {
    background-color: rgba(0, 0, 0, 0.6);
  }
</style>
