<template>
  <section class="admin-page wh100">
    <router-link to="/">
      <font-awesome-icon class="home-icon fixed" icon="home" />
    </router-link>
    <div class="drawer-menu fixed">
      <div class="user-account relative flex">
        <img src="../assets/images/user-picture.svg" />
        <p>admin</p>
      </div>
      <div class="menu-option flex">
        <img src="../assets/images/admin.svg" />
        <router-link :to="{ name: 'Guest' }">Accounts</router-link>
        <img src="../assets/images/arrow-right.svg" />
      </div>
    </div>
    <router-view />
  </section>
</template>

<script>
  export default {
    name: 'Admin'
  };
</script>

<style lang="scss">
  .admin-page {
    background: #fbfbff;
    .home-icon {
      top: 10px;
      right: 50px;
      transform: scale(1.2);
    }
    .drawer-menu {
      height: 100vh;
      width: 290px;
      background: #7b87f4;
      box-shadow: 0 0 43px 2px rgba(0, 0, 0, 0.25);
      color: white;
      font-weight: 500;
      font-family: Roboto, Arial, sans-serif;
      font-size: 20px;
      line-height: 23px;

      .user-account {
        margin: 20px;

        :nth-child(2) {
          margin: 30px 15px;
        }
      }
      .menu-option {
        margin-top: 100px;
        a {
          color: white;
        }
        :first-child {
          margin: 0 30px;
        }

        :nth-child(3) {
          margin-left: 80px;
        }
      }
    }
  }
</style>
