<template>
  <validation-provider
    class="base-checkbox-input"
    :rules="rules"
    v-slot="{ errors }"
  >
    <input
      type="checkbox"
      :value="value"
      v-model="state"
      :name="name"
      :id="name"
      @change="handleCheck"
    />
    <label :for="name" :class="{ 'has-text-danger': errors[0] }">{{
      label
    }}</label>
  </validation-provider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  export default {
    name: 'BaseCheckboxInput',
    props: {
      name: String,
      rules: String,
      label: String,
      value: String
    },
    components: {
      ValidationProvider
    },
    data () {
      return {
        state: undefined
      };
    },
    methods: {
      handleCheck () {
        this.$emit('selectCheckbox', {
          name: this.name,
          value: this.value,
          state: this.state
        });
      }
    }
  };
</script>

<style lang="scss">
  .base-checkbox-input {
    input {
      margin-left: 14px;
      transform: scale(1.3);
    }
    label {
      margin-left: 14px;
      display: inline-block;
      font-size: 20px;
      cursor: pointer;
      transition: transform 100ms ease-in-out;
      transform-origin: left;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
</style>
