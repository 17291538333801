<template>
  <section class="ansamblu-page main-page edit-object-ansamblu relative">
    <router-link class="button is-primary fixed has-text-white" to="/objects">
      Lista obiecte
    </router-link>
    <BaseConfirmationModal v-if="isConfirmRestoreAccount">
      <div class="notify-modal-ok text-center">
        <p>Obiect readăugat în listă</p>
        <button
          class="button is-success"
          cy="confirm-delete"
          @click.stop="isConfirmRestoreAccount = false"
        >
          OK
        </button>
      </div>
    </BaseConfirmationModal>

    <transition name="modal-fade">
      <BaseConfirmationModal v-if="isConfirmDeleteObject">
        <div class="notify-modal-ok confirm-delete-box text-center">
          <p>
            Sunteti sigur ca doriti sa stergeti fisa {{ object.cod_inventar }}?
          </p>
          <button class="button is-success" @click.stop="deleteObject">
            DA
          </button>
          <button class="button is-light" @click.stop="cancelDelete">NU</button>
        </div>
      </BaseConfirmationModal>
    </transition>

     <transition name="modal-fade" >
      <BaseConfirmationModal v-if="isConfirmDeleteObiecteAnsamblu">
        <div class="notify-modal-ok confirm-delete-box text-center">
          <p> Sunteti sigur ca doriti sa stergeti obiectul  
            {{selectedAnsambluObjectId}}
          </p>
            <button class="button is-success"
                    @click.stop="deleteObiectAnsamblu">DA</button>
            <button class="button is-light"
                    @click.stop="cancelDelete">NU</button> 
        </div>
      </BaseConfirmationModal>
    </transition> 

    <h1 class="title">Fișă ansamblu</h1>

    <div
      v-if="$store.state.isFetchingData"
      class="flex-center fixed wh100 left0 top0 modal-grey-background z99"
    >
      <BaseLoaderSpinner label="Sending data" />
    </div>

    <BaseForm @submit="handleUpdate">
      <BaseInput
        name="nume"
        label="Nume ansamblu"
        @input="handleInput"
        rules="required"
        :value="object.nume"
      />

      <div class="main-images-wrapper flex flex-wrap flex-between">
        <BaseFileInput
          v-for="(item, index) in 20"
          :key="index"
          :cypress="`image-${item}`"
          :name="`image-${item}`"
          :label="`Imagine - ${item}`"
          @handle-file="handleInput"
          @remove-file="handleRemoveFile"
          :rules="item < 11 ? 'required' : ''"
          :src="setImgPath(`image-${item}`)"
          :value="setImgPath(`image-${item}`)"
          :imgPreviewLabel="setImgPreviewLabel(item)"
        />
      </div>

      <BaseMultiSelect
          name="echipa"
          @input="handleMultiSelect"
          :list="echipa"
          :taggable="true"
          :preserveSearch="true"
          :hideSelected="true"
          label="Echipa"
          :value="object.echipa"
      />
      <BaseInput
        name="data_achizitie"
        label="Data achiziției"
        @input="handleInput"
        :value="object.data_achizitie"
      />
      <BaseInput
        name="localitatea_achizitie"
        label="Localitatea de achiziție"
        @input="handleInput"
        :value="object.localitatea_achizitie"
      />
      <BaseInput
        name="sursa"
        label="Sursă/Familia"
        @input="handleInput"
        :value="object.sursa"
      />

      <BaseInputText
        name="context_achizitie"
        label="Context achiziție"
        @input="handleInput"
        placeholder="Alte informații utile"
        :value="object.context_achizitie"
      />

      <BaseInputText
        name="descriere_ansamblu"
        label="Descriere ansamblu"
        @input="handleInput"
        placeholder="Descriere ansamblu"
        :value="object.descriere_ansamblu"
      />

      <BaseInput
        name="datare"
        label="Datare"
        @input="handleInput"
        placeholder="Datare"
        :value="object.datare"
      />

      <BaseMultiSelect
        name="provenienta"
        :allow-empty="false"
        label="Cat. 2 - proveniență"
        @input="handleMultiSelect"
        placeholder="Proveniență"
        type="dropdown"
        :list="provenienta"
        :value="object.provenienta"
      />

      <BaseInputText
        name="detalii_provenienta"
        label="Detalii proveniență"
        @input="handleInput"
        :value="object.detalii_provenienta"
      />

      <BaseMultiSelect
        name="etnie"
        @input="handleMultiSelect"
        :list="etnie"
        label="Etnie"
        type="dropdown"
        :value="object.etnie"
      />

      <BaseInputText
        name="uz"
        label="Cat. 4 - uz"
        @input="handleInput"
        placeholder="uz"
        :value="object.uz"
      />
      <BaseInput
        name="interior_exterior"
        label="Cat. 5 - interior/exterior"
        @input="handleInput"
        placeholder="interior/exterior"
        :value="object.interior_exterior"
      />

      <BaseMultiSelect
        name="etichete"
        @input="handleMultiSelect"
        label="Etichete"
        :list="etichete"
        :multiple="true"
        :taggable="true"
        :value="object.etichete"
      />

      <BaseInput
        name="valoare"
        label="Valoare"
        @input="handleInput"
        placeholder="Valoare"
        :value="object.valoare"
      />

      <BaseInputText
        name="functie"
        label="Funcția trecută-prezentă"
        @input="handleInput"
        :value="object.functie"
      />

      <footer class="fixed bottom0 w100 flex-center">
        <button class="button is-info" @click.prevent="savePdf">
          <font-awesome-icon cy="file-pdf-icon" icon="file-pdf" />
        </button>
        <button class="button is-primary" cy="save-ansamblu">
          <b>SAVE</b>
          <font-awesome-icon
            class="inline-block ml-2"
            cy="check-icon"
            icon="check"
          />
        </button>
      </footer>
    </BaseForm>

    <section class="obiecte-ansamblu text-left">
      <h2>
        <b>Obiecte in ansamblu</b>
      </h2>
      <ul class="objects-ansamblu">
        <li
          class="object-ansamblu"
          v-for="({ cod_inventar, id, nume }, index) in obiecteAnsamblu"
          :key="index"
        >
          <div>
            <router-link :to="`/edit-object/${id}`">
              {{ cod_inventar }}
            </router-link>
            <span>{{ nume }}</span>
          </div>
          <font-awesome-icon
            class="icon-trash-obj-ansamblu"
            cy="trash-icon"
            icon="trash-alt" 
            @click="confirmDeleteObiecteAnsamblu(id)"
            />
        </li>
      </ul>
    </section>
    <div class="side-actions fixed">
      <!--  DELETE / RESTORE obj  -->
      <button
        v-show="!object.deleted"
        class="delete-btn button is-danger"
        @click="confirmDeleteOjbect"
      >
        <font-awesome-icon cy="trash-icon" icon="trash-alt" />
      </button>
      <button
        v-show="object.deleted"
        class="delete-btn button is-danger"
        @click="restoreObject"
      >
        <font-awesome-icon
          v-show="object.deleted"
          cy="trash-restore-icon"
          icon="trash-restore"
        />
      </button>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'Ansamblu',
    created () {
      this.$store.dispatch('get_object', this.$route.params.id);
      this.$store.dispatch('get_obiecte_ansamblu', this.$route.params.id);
    },
    data () {
      return {
        formData: new FormData(),
        imgsToDelete: [],
        isConfirmDeleteObject: false,
        isConfirmRestoreAccount: false,
        isConfirmDeleteObiecteAnsamblu: false,
        selectedAnsambluObjectId: undefined
      };
    },
    computed: {
      ...mapState({
        object: (state) => state.objects.object,
        procesRestaurare: (state) => state.objects.procesRestaurare,
        echipa: (state) => state.objects.echipa,
        provenienta: (state) => state.objects.provenienta,
        etnie: (state) => state.objects.etnie,
        etichete: (state) => state.objects.etichete,
        obiecteAnsamblu: (state) => state.objects.obiecteAnsamblu
      })
    },
    methods: {
      savePdf () {
        this.$store.dispatch('save_pdf', this.$router.currentRoute.params.id);
      },
      restoreObject () {
        const clbk = () => {
          this.isConfirmRestoreAccount = true;
        };
        this.$store.dispatch('restore_deleted', {
          id: this.object._id,
          clbk
        });
      },
      confirmDeleteOjbect () {
        this.isConfirmDeleteObject = true;
      },
      confirmDeleteObiecteAnsamblu (id) {
        this.isConfirmDeleteObiecteAnsamblu = true;
        this.selectedAnsambluObjectId = id;
      },
      deleteObiectAnsamblu () {
        this.isConfirmDeleteObiecteAnsamblu = false;
        this.$store.dispatch(
          'delete_object_ansamblu', {
            ansambluId: this.$router.currentRoute.params.id, 
            idObject: this.selectedAnsambluObjectId
          }
        );
      },

      deleteObject () {
        this.isConfirmDeleteObject = false;
        this.$store.dispatch(
          'delete_object',
          this.$router.currentRoute.params.id
        );
      },
      cancelDelete () {
        this.isConfirmDeleteObject = false;
        this.isConfirmDeleteObiecteAnsamblu = false;
      },
      handleUpdate () {
        this.$store.dispatch('update_object', {
          formData: this.formData,
          id: this.$route.params.id
        });
      },
      handleInput ({ name, value, type }) {
        if (type === 'file') {
          const idx = this.imgsToDelete.indexOf(name);
          this.imgsToDelete.splice(idx, 1);
        }
        this.formData.delete(name);
        this.formData.append(name, value);
      },
      handleRemoveFile ({ name }) {
        this.imgsToDelete.push(name);
        this.formData.delete('imgsToDelete');
        this.formData.append('imgsToDelete', JSON.stringify(this.imgsToDelete));
        this.formData.delete(name);
      },
      setImgPath (imgName) {
        return this.object.imgs &&
          this.object.imgs.includes(`${imgName}-thumb.png`)
          ? `${this.object._id}/${imgName}-thumb.png`
          : '';
      },
      setImgPreviewLabel (item) {
        const no = item.toString();
        const suffix = no.padStart(2, '0');
        return `${this.object.cod_inventar}_${suffix}`;
      },
      handleMultiSelect ({ name, type, value }) {
        /* value poate fi un array de obiecte say string */
        this.formData.delete(name);
        this.formData.append(
          name,
          type === 'dropdown' ? value : JSON.stringify(value)
        );
        const index = this.procesRestaurare.indexOf(value);
        index > -1 &&
          (this.restaurareColor = this.procesRestaurare[index]
            .replace(/ /g, '-')
            .toLowerCase());
      }
    }
  };
</script>

<style lang="scss">
  .ansamblu-page {
    ul.objects-ansamblu {
      margin-top: 10px;
    }
    li.object-ansamblu {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      .icon-trash-obj-ansamblu {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.562);
        font-size: 18px;
      }
    }
    padding-bottom: 320px !important;
    & > a.button.fixed {
      right: 24px;
      top: 42px;
    }
    div.main-images-wrapper {
      margin-top: 16px;
      & > span:last-child {
        margin-right: auto;
        margin-left: 31px;
      }
    }
    span.base-file-input {
      margin-right: 12px;
      margin-bottom: 15px;
    }
    .base-input,
    .base-multiselect,
    .base-textarea {
      margin-bottom: 15px;
    }
    .obiecte-ansamblu {
      max-width: 400px;
      margin: 0 auto;
    }
  }
</style>
