import axios from 'axios';
import api from '../api';
import Vue from 'vue';

export const state = function () {
  return {
    ansambluri: []
  };
};

export const actions = {
  async get_ansambluri ({ state, commit }) {
    try {
      commit('FETCHING_DATA', true);
      const { data } = await axios(api.ansamblu);
      commit('SET_ANSAMBLURI', data);
      commit('FETCHING_DATA', false);
      commit('SET_STATE', { key: 'isModal', value: true });
    } catch (err) {
      commit('FETCHING_DATA', false);
      console.log(err);
    }
  },

}


export const mutations = {
  SET_ANSAMBLURI (state, ansambluri) {
    Vue.set(state, 'ansambluri', ansambluri);
  }
};
