<template>
  <main
    class="login-page is-flex is-flex-direction-column  wh100"
  >
    <div class="mb-6">
      <h1 class="title">
        <b>Colecție privată de patrimoniu etnografic arhivată digital</b>
      </h1>
      <h2>Login</h2>
      <BaseForm @submit="handleSubmit">
        <BaseInput
          name="username"
          label="email"
          @input="handleInput"
          placeholder="email address"
          rules="required|email"
        />
        <BaseInput
          name="password"
          :type="type"
          @input="handleInput"
          placeholder="password"
          rules="required"
        >
          <div v-if="form.password">
            <font-awesome-icon
              v-if="type === 'password'"
              @click="showPassword"
              class="icon-show"
              icon="eye"
            />
            <font-awesome-icon
              v-else
              @click="hidePassword"
              class="icon-show icon-close"
              icon="eye-slash"
            />
          </div>
        </BaseInput>

        <p v-if="loginErr" class="has-text-danger mt-4 text-right">
          Date de autentificare incorecte.
        </p>
        <button
          cypress="submit-login"
          class="flex m-left-auto button is-primary"
        >
          SUBMIT
        </button>
      </BaseForm>
    </div>
    <footer class="display-logo-images">
      <figure class="figure-display">
        <span>Susținut de</span>
        <img src="../assets/logos/asociatia-dedeman.png" alt="">
      </figure>
      <figure class="figure-display"> 
        <img src="../assets/logos/fid.jpg"  alt="" class="fid-logo">
      </figure>
      <figure class="figure-display">
        <span>Realizat exclusiv cu tehnologie</span>
        <img src="../assets/logos/samsung.png"  alt="samsung" class="samsung-logo">
      </figure>
    </footer>

    <!-- <footer class="flex-center mt-6">
      <figure>
        <span class="block mb-0">Susținut de</span>
        <img src="../assets/logos/asociatia-dedeman.png" alt="dedeman" />
      </figure>
      <figure class="ml-6 mb-6">
        <img src="../assets/logos/fid.jpg" alt="fid" class="fid-logo" />
      </figure>
      <figure class="mr-2">
        <span class="block mb-0">Realizat exclusiv cu tehnologie</span>
        <img src="../assets/logos/samsung.png" alt="samsung" />
      </figure>
    </footer> -->
  </main>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: 'Login',
    data () {
      return {
        type: 'password',
        form: {
          username: undefined,
          password: undefined
        }
      };
    },
    computed: {
      ...mapState({
        loginErr: (state) => state.auth.loginErr
      })
    },
    methods: {
      showPassword () {
        this.type = 'text'

      },
      hidePassword () {
        this.type = 'password'

      },
      handleSubmit () {
        this.$store.dispatch('login', this.form);
      },
      handleInput ({ name, value }) {
        this.form[name] = value;
      }
    }
  };
</script>

<style lang="scss">
  .login-page {
    .icon-show {
      opacity: 0.8;
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 41px;
    }
    .icon-close {
      right: 19px;
    }
    padding: 60px 0 24px;
    h2 {
      margin-top: 60px;
      font-size: 1.4rem;
    }
    .display-logo-images{
      margin: 0 auto;
      display: flex;
      align-items: flex-end;
      .figure-display {
        display: block;
        width: 200px;
        margin: 24px;
        img {
          width: 200px;
          padding-bottom: 0;
        }
        .fid-logo{
          width: 130px;
          transform: translateY(-9px);
        }
        .samsung-logo {
          transform: translateY(5px);
        }
      }
    }

    // footer {
    //   // padding: 0 60px;
    //   figure:last-child {
    //     margin-left: 60px;
    //     img {
    //       transform: translateY(4px);
    //     }
    //   }
    //   img {
    //     width: 200px;
    //   }
    //   .fid-logo {
    //     width: 130px;
    //     height: 124px;
    //   }
    // }
  }
</style>
