<template>
  <div
    class="base-dropdown relative"
    v-click-outside="closeDropdown"
    :class="{ 'vue-active': toggle }"
  >
    <span @click="toggleDropdown()">{{
      value.length ? value : defaultValue
    }}</span>
    <div class="vue-dropdown-collapsed" v-if="this.toggle">
      <ul>
        <li
          v-for="(option, index) in options"
          :key="option"
          :class="{ 'hover-item': index === itemIndex }"
          @click="selectOption(option, index)"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseDropdown',
    props: ['options', 'defaultValue'],
    data: function () {
      return {
        toggle: false,
        value: '',
        itemIndex: 0
      };
    },
    destroyed () {
      this.dropKb();
    },
    methods: {
      closeDropdown () {
        this.toggle = false;
      },
      handleKb ({ key }) {
        switch (key) {
          case 'ArrowUp':
            if (this.itemIndex > 0) {
              this.itemIndex -= 1;
            }
            break;
          case 'ArrowDown':
            if (this.itemIndex < this.options.length) {
              this.itemIndex += 1;
            }
            break;
          case 'Escape':
            this.toggleDropdown();
            break;
          case 'Enter':
            const value = this.options[this.itemIndex];
            const index = this.itemIndex;
            this.selectOption(value, index);
        }
      },
      dropKb () {
        window.removeEventListener('keyup', this.handleKb);
      },
      selectOption ({label, value}, index) {
        this.value = label;
        this.itemIndex = index;
        this.$emit('handleDropdownItem', {
          value
        });
        this.toggleDropdown();
      },
      toggleDropdown () {
        this.toggle = !this.toggle;
        this.toggle
          ? window.addEventListener('keyup', this.handleKb)
          : window.removeEventListener('keyup', this.handleKb);
      }
    }
  };
</script>

<style lang="scss">
  .base-dropdown {
    position: relative;
    margin-bottom: 10px;
    padding: 0 16px 0 8px;
    height: 30px;
    z-index: 98;
    .hover-item {
      background-color: lightgray;
    }

    span {
      padding: 7.5px 15px;
      border-radius: 4px;
      border: 1px solid #a8a8a8;
      display: block;
      cursor: pointer;
      text-align: center;
      align-items: center;
    }

    span:after {
      content: "\2193";
      color: hsl(171, 100%, 41%);
      position: absolute;
      right: 6px;
      transition: ease all 0.4s;
    }

    .vue-dropdown-collapsed {
      position: absolute;
      width: 100%;
    }
    ul {
      padding: 0 !important;
      margin: 0;
      background: #efefef;
    }

    li {
      margin: 0;
      list-style: none;
      padding: 10px 15px;
      border-bottom: 1px solid #fff;
      background-color: white;
      cursor: pointer;
    }

    li:hover {
      background-color: lightgray;
    }
  }
  .vue-active {
    span {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  .vue-active span:after {
    transform: rotate(180deg);
  }
</style>
