import axios from 'axios';
import api from '../api';

export const actions = {
  async save_pdf (context, id) {
    try {
      const resp = await axios(`${api.pdf}/${id}`, {
        responseType: 'blob'
      });
      const file = new Blob([resp.data], { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL, '_blank', 'channelmode=yes');
    } catch (err) {
      console.log(err);
    }
  },
  async restore_deleted ({ commit }, { id, clbk }) {
    try {
      const url = `${api.object}/restore-deleted/${id}`;
      await axios(url);
      commit('UN_DELETE_OBJECT');
      clbk();
    } catch (err) {}
  }
};
