<template>
  <section class="counting-info" @click.stop>
    <h6>
      <b>Total obiecte pe categorii</b>
    </h6>
    <br />
    <ul class="text-left flex flex-wrap">
      <li v-for="({ name, count }, index) in total" :key="index">
        {{ name }}: {{ count }}
      </li>
    </ul>
  </section>
</template>

<script>
  export default {
    name: 'CountingInfo',
    props: {
      total: Array
    }
  };
</script>

<style lang="scss">
  .counting-info {
    background-color: white;
    border-radius: 4px;
    padding: 24px;
    max-width: 600px;
    li {
      width: 200px;
    }
  }
</style>
