<template>
  <validation-provider
    class="base-input relative"
    :class="{ required: rules && rules.includes('required') }"
    :rules="rules"
    v-slot="{ errors }"
  >
    <label :for="name">{{ label || name }}</label>
    <input
      :type="type || 'text'"
      :cy="name"
      class="input w100"
      :id="name"
      :name="name"
      v-model="data"
      @input="input"
      :disabled="disabled"
      :placeholder="placeholder || label || name"
    >

    <slot/>

    <span
      class="has-text-danger"
      v-if="errors && errors[0]"
      :cypress="name + '-required-field'"
      >{{ errors && errors[0] }}</span
    >
  </validation-provider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  export default {
    name: 'BaseInput',
    components: {
      ValidationProvider
    },
    props: {
      name: String,
      label: String,
      placeholder: String,
      type: String,
      value: String,
      disabled: Boolean,
      rules: {
        type: String,
        default: ''
      }
    },
    mounted () {
      this.value && (this.data = this.value);
    },
    data () {
      return {
        data: ''
      };
    },
    watch: {
      value: function (val) {
        this.data = val;
      }
    },
    methods: {
      input ({ target: { value } }) {
        this.$emit('input', { name: this.name, value });
      }
    }
  };
</script>

<style lang="scss">
  .base-input {
    display: block;
    label,
    input {
      display: block;
    }
    label {
      margin-bottom: 4px;
    }
    input + span {
      margin-top: 4px;
      display: block;
    }
  }
</style>
