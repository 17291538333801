<template>
  <validation-provider
    class="base-date-picker relative block"
    :class="{ required: rules.includes('required') }"
    ref="observer"
    :rules="rules"
    v-slot="{ errors }"
  >
    <label :for="name || id">{{ label }}</label>
    <font-awesome-icon class="absolute" icon="calendar-alt" />
    <datetime
      :name="name"
      :cy="name"
      v-model="date"
      :placeholder="placeholder || label"
      @input="selectDate"
    />
    <span v-if="validate && errors[0]" class="has-text-danger">{{
      errors[0]
    }}</span>
  </validation-provider>
</template>

<script>
  import { Datetime } from 'vue-datetime';
  import { ValidationProvider } from 'vee-validate';
  import { format } from 'date-fns';
  export default {
    name: 'BaseDatePicker',
    props: {
      name: String,
      id: String,
      label: String,
      placeholder: String,
      rules: {
        type: String,
        default: ''
      },
      default: Datetime,
      value: {
        type: Datetime | String,
        required: false
      }
    },
    components: {
      Datetime,
      ValidationProvider
    },
    data () {
      return {
        date: undefined,
        validate: false,
        format
      };
    },
    created () {
      if (this.default) {
        const defaultDate = +this.format(this.default, 'yyyyMMdd') + 1;
        this.date = defaultDate.toString();
      }
      if (this.value) {
        this.date = this.value;
      }
    },
    methods: {
      selectDate (date) {
        date &&
          this.$emit('input', {
            name: this.name,
            value: date
          });
        /* datepicker has an anomaly for calling the input fn at page load, which
         * triggers validation; so we fix with the bellow, by conditioning the validation */
        this.$nextTick(() => {
          this.$refs.observer.reset();
          this.validate = true;
        });
      }
    },
    watch: {
      value: function (newVal) {
        newVal && (this.date = this.format(new Date(newVal), 'yyyyMMdd'));
      }
    }
  };
</script>

<style lang="scss">
  .base-date-picker {
    margin-bottom: 0;
    --input-placeholder-opacity: 0.6;
    label {
      display: block;
      margin-bottom: 4px;
    }
    svg.absolute {
      top: 39px;
      right: 12px;
      opacity: 0.7;
    }
    .vdatetime {
      input {
        width: 100%;
        padding: 7px 11px;
        font-size: 1rem;
        border-radius: 4px;
        border: 1px solid #dbdbdb;
        line-height: 1.5;
        box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.5);
        -webkit-box-shadow: 0 0 0 1000px white inset;
        cursor: default;
      }
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        opacity: var(--input-placeholder-opacity);
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        opacity: var(--input-placeholder-opacity);
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        opacity: var(--input-placeholder-opacity);
      }
      :-moz-placeholder {
        /* Firefox 18- */
        opacity: var(--input-placeholder-opacity);
      }
    }
  }
</style>
