<template>
  <section class="edit-object-page main-page edit-object-ansamblu relative">
    <transition name="modal-fade">
      <BaseConfirmationModal v-if="isConfirmDeleteObject">
        <div class="notify-modal-ok confirm-delete-box text-center">
          <p>
            Sunteti sigur ca doriti sa stergeti fisa {{ object.cod_inventar }}?
          </p>
          <button class="button is-success" @click.stop="deleteObject">
            DA
          </button>
          <button class="button is-light" @click.stop="cancelDelete">NU</button>
        </div>
      </BaseConfirmationModal>
    </transition>

    <BaseConfirmationModal v-if="isConfirmRestoreAccount">
      <div class="notify-modal-ok text-center">
        <p>Obiect readăugat în listă</p>
        <button
          class="button is-success"
          cy="confirm-delete"
          @click.stop="isConfirmRestoreAccount = false"
        >
          OK
        </button>
      </div>
    </BaseConfirmationModal>

    <transition name="modal-fade">
      <BaseModal>
        <ListaAnsambluriModal
          :list="ansambluri"
          :ansamblu="object.ansamblu"
          @link-ansamblu="linkAnsamblu"
        />
      </BaseModal>
    </transition>

    <h1 class="title">
      Editeaza obiectul
      <span v-if="object.ansamblu" class="block">
        <router-link :to="`/ansamblu/${object.ansamblu.id}`">
          <b>ansamblu: {{ object.ansamblu.nume }}</b>
        </router-link>
      </span>
    </h1>

    <router-link class="button is-primary fixed has-text-white" to="/objects">
      Lista obiecte
    </router-link>

    <BaseForm @submit="handleUpdate">
      <BaseInput
        name="nume"
        label="Nume obiect"
        @input="handleInput"
        rules="required"
        :value="object.nume"
      />
      <BaseInput
        name="cod_inventar"
        label="Cod inventar"
        @input="handleInput"
        rules="required"
        :disabled="true"
        :value="object.cod_inventar"
      />

      <BaseDatePicker
        cy="datepicker_inventar_edit"
        name="data_inventarierii"
        label="Data inventarierii"
        @input="handleInput"
        rules="required"
        :default="new Date()"
        :value="object.data_inventarierii"
      />

      <BaseInputText
        name="dimensiuni"
        label="Dimensiuni"
        @input="handleInput"
        placeholder="H / L / l"
        rules="required"
        :value="object.dimensiuni"
      />

      <div class="flex flex-wrap flex-between">
        <BaseFileInput
          v-for="(item, index) in 10"
          :key="index"
          :cypress="`image-${item}`"
          :name="`image-${item}`"
          :label="`Imagine - ${item}`"
          @handle-file="handleInput"
          @remove-file="handleRemoveFile"
          :rules="item < 6 ? 'required' : ''"
          :src="setImgPath(`image-${item}`)"
          :value="setImgPath(`image-${item}`)"
          :imgPreviewLabel="setImgPreviewLabel(item)"
        />
      </div>

      <BaseMultiSelect
        name="echipa"
        @input="handleMultiSelect"
        :list="echipa"
        :taggable="true"
        :preserveSearch="true"
        :hideSelected="true"
        label="Echipa"
        :value="object.echipa"
      />
      <BaseInput
        name="data_achizitie"
        label="Data achiziției"
        @input="handleInput"
        :value="object.data_achizitie"
      />
      <BaseInput
        name="localitatea_achizitie"
        label="Localitatea de achiziție"
        @input="handleInput"
        :value="object.localitatea_achizitie"
      />
      <BaseInput
        name="sursa"
        label="Sursă/Familia"
        @input="handleInput"
        :value="object.sursa"
      />

      <BaseInputText
        name="context_achizitie"
        label="Context achiziție"
        @input="handleInput"
        placeholder="Alte informații utile"
        :value="object.context_achizitie"
      />

      <BaseMultiSelect
        name="stadiu_conservare"
        @input="handleMultiSelect"
        :list="listConservare"
        label="Stadiu de conservare"
        type="dropdown"
        :value="object.stadiu_conservare"
      />

      <BaseInputText
        name="stadiu_conservare_detalii"
        label="Descriere stadiu conservare"
        @input="handleInput"
        :value="object.stadiu_conservare_detalii"
      />
      <BaseInputText
        name="descriere_obiect"
        label="Descriere obiect"
        @input="handleInput"
        :value="object.descriere_obiect"
      />

      <BaseInput
        name="datare"
        label="Datare"
        @input="handleInput"
        :value="object.datare"
      />
      <BaseMultiSelect
        name="material"
        label="Cat. 1 - material"
        @input="handleMultiSelect"
        placeholder="Material"
        :list="materiale"
        :multiple="true"
        :taggable="true"
        :value="object.material"
      />

      <BaseInputText
        name="detalii_material"
        label="Detalii material"
        @input="handleInput"
        :value="object.detalii_material"
      />
      <BaseMultiSelect
        name="provenienta"
        :allow-empty="false"
        label="Cat. 2 - proveniență"
        @input="handleMultiSelect"
        placeholder="Proveniență"
        type="dropdown"
        :list="provenienta"
        :value="object.provenienta"
      />

      <BaseInputText
        name="detalii_provenienta"
        label="Detalii proveniență"
        @input="handleInput"
        :value="object.detalii_provenienta"
      />
      <BaseMultiSelect
        name="etnie"
        @input="handleMultiSelect"
        :list="etnie"
        label="Etnie"
        type="dropdown"
        :value="object.etnie"
      />

      <BaseInputText
        name="uz"
        label="Cat. 4 - uz"
        @input="handleInput"
        placeholder="uz"
        :value="object.uz"
      />
      <BaseInput
        name="interior_exterior"
        label="Cat. 5 - interior/exterior"
        @input="handleInput"
        placeholder="interior/exterior"
        :value="object.interior_exterior"
      />

      <BaseMultiSelect
        name="etichete"
        @input="handleMultiSelect"
        label="Etichete"
        :list="etichete"
        :multiple="true"
        :taggable="true"
        :value="object.etichete"
      />

      <BaseInputText
        name="conexiune_items"
        label="Conexiune cu alți itemi"
        @input="handleInput"
        :value="object.conexiune_items"
      />

      <BaseInput
        name="valoare"
        label="Valoare"
        @input="handleInput"
        placeholder="Valoare"
        :value="object.valoare"
      />

      <BaseInputText
        name="functie"
        label="Funcția trecută-prezentă"
        @input="handleInput"
        :value="object.functie"
      />
      <BaseInputText
        name="propuneri_conservare"
        label="Propuneri pentru conservare"
        @input="handleInput"
        :value="object.propuneri_conservare"
      />

      <BaseMultiSelect
        name="stare_conservare"
        @input="handleMultiSelect"
        placeholder="Stare de conservare"
        :list="listConservare"
        type="dropdown"
        label="Stare de conservare"
        :value="object.stare_conservare"
      />

      <BaseInputText
        name="materiale_tehnologii"
        label="Materiale și tehnologii"
        @input="handleInput"
        :value="object.materiale_tehnologii"
      />
      <BaseInputText
        name="analize"
        label="Analize fizice/chimice/biologice"
        @input="handleInput"
        :value="object.analize"
      />
      <BaseInputText
        name="anamneza"
        label="Anamneza obiectului"
        @input="handleInput"
        :value="object.anamneza"
      />
      <BaseInputText
        name="conditii_pastrare"
        label="Condiții de păstrare a obiectului"
        @input="handleInput"
        :value="object.conditii_pastrare"
      />

      <BaseInputText
        name="aprecieri_conditii_pastrare"
        label="Aprecieri privind condițiile de păstrare"
        @input="handleInput"
        :value="object.aprecieri_conditii_pastrare"
      />
      <BaseInputText
        name="restaurari"
        label="Restaurări - dată și număr"
        @input="handleInput"
        placeholder="Data și numărul restaurărilor"
        :value="object.restaurari"
      />
      <BaseInputText
        name="mentiuni"
        label="Mențiuni speciale"
        @input="handleInput"
        :value="object.mentiuni"
      />

      <BaseDatePicker
        cy="data_intocmire-fisa"
        name="data_fisa"
        label="Data intocmirii fisei"
        placeholder="Data întocmirii fișei"
        @input="handleInput"
        :value="object.data_fisa"
        :default="new Date()"

      />
      <BaseMultiSelect
        name="conservator"
        @input="handleMultiSelect"
        :list="echipa"
        label="Conservator"
        type="dropdown"
        :value="object.conservator"
      />

      <BaseInputText
        name="interventii"
        label="Intervenții anterioare"
        @input="handleInput"
        :value="object.interventii"
      />
      <BaseInputText
        name="investigatii"
        label="Investigații"
        @input="handleInput"
        placeholder="Analize chimice/fizice/biologice"
        :value="object.investigatii"
      />
      <BaseInputText
        name="propuneri_tratament"
        label="Propuneri de tratament"
        @input="handleInput"
        placeholder="Propuneri de tratament/PV Comisie de Restaurare"
        :value="object.propuneri_tratament"
      />
      <BaseInputText
        name="lucrari_restaurare"
        label="Descrierea lucrărilor de restaurare"
        @input="handleInput"
        :value="object.lucrari_restaurare"
      />
      <BaseInputText
        name="concluzii"
        label="Concluzii și recomandări"
        @input="handleInput"
        :value="object.concluzii"
      />

      <BaseDatePicker
        name="data_interventie_restaurare"
        label="Data intervenției de restaurare"
        @input="handleInput"
        :value="object.data_interventie_restaurare"

      />
      <BaseMultiSelect
        name="restaurator"
        @input="handleMultiSelect"
        :list="echipa"
        label="Restaurator"
        :multiple="true"
        :taggable="true"
        :value="object.restaurator"
      />
      <BaseMultiSelect
        name="status"
        :allow-empty="false"
        @input="handleMultiSelect"
        :list="status"
        label="Status"
        type="dropdown"
        :value="object.status || status[0]"
      />
      <BaseMultiSelect
        name="proces_restaurare"
        :allow-empty="false"
        @input="handleMultiSelect"
        :list="procesRestaurare"
        label="Proces restaurare"
        type="dropdown"
        :class="`proces-restaurare ${
          restaurareColor ||
          (object.proces_restaurare &&
            object.proces_restaurare.replace(/ /g, '-').toLowerCase())
        }`"
        :value="object.proces_restaurare"
      />

      <h3>Fotografii înainte de intervenție</h3>
      <div class="imgs-wrapper flex flex-wrap flex-between">
        <div class="img-box" v-for="(item, index) in 5" :key="index">
          <BaseFileInput
            :name="`foto-inainte-${item}`"
            type="file"
            @handle-file="handleInput"
            @remove-file="handleRemoveFile"
            :label="`Foto ${item}`"
            :imgPreviewLabel="setImgPreviewLabel(item)"
            :src="setImgPath(`foto-inainte-${item}`)"
            :value="setImgPath(`foto-inainte-${item}`)"
          />
        </div>
      </div>

      <h3>Fotografii după intervenție</h3>
      <div class="imgs-wrapper flex flex-wrap flex-between">
        <div class="img-box" v-for="(item, index) in 5" :key="index">
          <BaseFileInput
            :name="`foto-dupa-${item}`"
            type="file"
            @handle-file="handleInput"
            @remove-file="handleRemoveFile"
            :label="`Foto ${item}`"
            :imgPreviewLabel="setImgPreviewLabel(item)"
            :src="setImgPath(`foto-dupa-${item}`)"
            :value="setImgPath(`foto-dupa-${item}`)"
          />
        </div>
      </div>

      <footer class="fixed bottom0 w100 flex-center">
        <button class="button is-info" @click.prevent="savePdf">
          <font-awesome-icon cy="file-pdf-icon" icon="file-pdf" />
        </button>
        <button class="button is-primary" cy="save-object">
          <b>SAVE</b>
          <font-awesome-icon
            class="inline-block ml-2"
            cy="check-icon"
            icon="check"
          />
        </button>
      </footer>
    </BaseForm>

    <div
      v-if="$store.state.isFetchingData"
      class="flex-center fixed wh100 left0 top0 modal-grey-background z99"
    >
      <BaseLoaderSpinner label="Sending data" />
    </div>

    <div class="side-actions fixed">
      <button
        class="button is-light mr-2"
        @click="addToAnsamblu"
        cy="add-to-ansamblu"
      >
        <font-awesome-icon cy="layer-group-icon" icon="layer-group" />
      </button>
      <!--  DELETE / RESTORE obj  -->
      <button
        v-show="!object.deleted"
        class="delete-btn button is-danger "
        @click="confirmDeleteOjbect"
      >
        <font-awesome-icon cy="trash-icon" icon="trash-alt" />
      </button>
      <button
        v-show="object.deleted"
        class="delete-btn button is-danger"
        @click="restoreObject"
      >
        <font-awesome-icon
          v-show="object.deleted"
          cy="trash-restore-icon"
          icon="trash-restore"
        />
      </button>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import { format } from 'date-fns';
  import ListaAnsambluriModal from '@/components/ListaAnsambluriModal';

  export default {
    name: 'EditObject',
    components: {
      ListaAnsambluriModal
    },
    data () {
      return {
        isConfirmRestoreAccount: false,
        isConfirmDeleteObject: false,
        formData: new FormData(),
        listConservare: ['DETERIORAT', 'MEDIE', 'BUNĂ', 'FOARTE BUNĂ'],
        value: '',
        tag: [{ name: 'metal', code: 'me' }],
        format,
        name: '',
        materiale: [
          'lemn',
          'metal',
          'sticlă',
          'textil',
          'piele',
          'piatră',
          'ceramică'
        ],
        status: [
          'în colecție',
          'Dep. Ext.',
          'Gamma',
          'Rest. Ext.',
          'împrumutat',
          'pierdut',
          'distrus',
          'vândut',
          'schimb',
          'donație'
        ],
        imgsToDelete: [],
        restaurareColor: ''
      };
    },
    computed: {
      ...mapState({
        object: (state) => state.objects.object,
        procesRestaurare: (state) => state.objects.procesRestaurare,
        echipa: (state) => state.objects.echipa,
        provenienta: (state) => state.objects.provenienta,
        etnie: (state) => state.objects.etnie,
        etichete: (state) => state.objects.etichete,
        ansambluri: (state) => state.ansamblu.ansambluri
      })
    },
    created () {
      this.formData.append('imgsToDelete', JSON.stringify(this.imgsToDelete));
      this.$store.dispatch('get_object', this.$route.params.id);
    },
    methods: {
      linkAnsamblu (ansamblu) {
        this.$store.dispatch('link_object_to_ansamblu', ansamblu);
      },
      addToAnsamblu () {
        this.$store.dispatch('get_ansambluri');
      },
      savePdf () {
        this.$store.dispatch('save_pdf', this.$router.currentRoute.params.id);
      },
      restoreObject () {
        const clbk = () => {
          this.isConfirmRestoreAccount = true;
        };
        this.$store.dispatch('restore_deleted', {
          id: this.object._id,
          clbk
        });
      },
      confirmDeleteOjbect () {
        this.isConfirmDeleteObject = true;
      },
      deleteObject () {
        this.isConfirmDeleteObject = false;
        this.$store.dispatch(
          'delete_object',
          this.$router.currentRoute.params.id
        );
      },
      cancelDelete () {
        this.isConfirmDeleteObject = false;
      },
      setImgPreviewLabel (item) {
        const no = item.toString();
        const suffix = no.padStart(2, '0');
        return `${this.object.cod_inventar}_${suffix}`;
      },
      addTag (newTag) {
        const tag = {
          name: newTag,
          code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
        };
        this.etichete.push(tag);
        this.tag.push(tag);
      },
      handleInput ({ name, value, type }) {
        if (type === 'file') {
          const idx = this.imgsToDelete.indexOf(name);
          this.imgsToDelete.splice(idx, 1);
        }
        this.formData.delete(name);
        this.formData.append(name, value);
      },
      handleMultiSelect ({ name, type, value }) {
        /* value poate fi un array de obiecte say string */
        this.formData.delete(name);
        this.formData.append(
          name,
          type === 'dropdown' ? value : JSON.stringify(value)
        );
        const index = this.procesRestaurare.indexOf(value);
        index > -1 &&
          (this.restaurareColor = this.procesRestaurare[index]
            .replace(/ /g, '-')
            .toLowerCase());
      },
      handleRemoveFile ({ name }) {
        this.imgsToDelete.push(name);
        this.formData.delete('imgsToDelete');
        this.formData.append('imgsToDelete', JSON.stringify(this.imgsToDelete));
        this.formData.delete(name);
      },
      handleUpdate () {
        this.$store.dispatch('update_object', {
          formData: this.formData,
          id: this.$route.params.id
        });
      },
      setImgPath (imgName) {
        return this.object.imgs &&
          this.object.imgs.includes(`${imgName}-thumb.png`)
          ? `${this.object._id}/${imgName}-thumb.png`
          : '';
      }
    },
    destroyed () {
      this.$store.commit('SET_OBJECT', {});
    }
  };
</script>

<style lang="scss">
  .proces-restaurare {
    .multiselect__content-wrapper {
      ul {
        li:first-child {
          color: black;
        }

        li:nth-child(2) {
          color: red;
        }

        li:nth-child(3) {
          color: orange;
        }

        li:nth-child(4) {
          color: green;
        }
      }
    }

    .multiselect__option--highlight {
      background: none;
      color: inherit;
    }
  }

  .urgent {
    .multiselect__single {
      color: red;
    }
  }

  .în-curs {
    .multiselect__single {
      color: orange;
    }
  }

  .finalizat {
    .multiselect__single {
      color: green;
    }
  }

  .edit-object-page {
    padding-bottom: 120px !important;
    .title {
      span {
        padding-top: 8px;
        font-size: 1.2rem;
      }
    }
    & > a.button.fixed {
      right: 24px;
      top: 42px;
    }

    .base-input,
    .base-textarea,
    .base-multiselect,
    .date-picker,
    .base-file-input {
      margin-bottom: 15px;
    }

    .imgs-wrapper .img-box {
      width: 33.33%;
    }

    .imgs-wrapper > .img-box:last-child {
      margin-right: auto;
    }

    .base-file-input {
      margin-right: 12px;
    }

    .vs__search::placeholder {
      color: lightgray;
    }

    h3 {
      margin-bottom: 8px;
      font-size: 20px;
    }
  }
</style>
