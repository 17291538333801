<template>
  <section class="page-not-found flex-center wh100">
    Pagina nu a fost gasita.
  </section>
</template>

<script>
  export default {
    name: 'PageNotFound'
  };
</script>

<style lang="scss">
  .page-not-found {
  }
</style>
