<template>
  <validation-provider
    class="base-file-input relative"
    :class="{ required: rules.includes('required') }"
    :rules="rules"
    :ref="name"
    v-slot="{ errors, validate }"
  >
    <transition name="modal-fade">
      <BaseConfirmationModal v-if="isConfirmRemoveImg">
        <div class="notify-modal-ok confirm-delete-box text-center">
          <p>Doriti sa stergeti imaginea "{{ label }}"?</p>
          <button class="button is-success" @click.stop="removeImage">
            DA
          </button>
          <button class="button is-light" @click.stop="cancelRemove">NU</button>
        </div>
      </BaseConfirmationModal>
    </transition>
    <transition name="modal-fade">
      <div
        v-if="isImgModalPreview"
        class="img-preview fixed wh100 left0 top0 flex-center z99"
      >
        <figure class="relative">
          <span>{{ imgPreviewLabel }}</span>
          <font-awesome-icon
            @click="imgModalPreview(false)"
            class="absolute pointer"
            icon="window-close"
          />
          <img
            ref="largePreview"
            :src="`/imgs/${value.replace('-thumb', '')}`"
            alt="image preview"
          />
        </figure>
      </div>
    </transition>
    <span :class="{ 'has-text-danger': errors[0] }">{{ label || name }}</span>
    <figure
      v-if="isPreview"
      class="flex-center relative"
      @click="imgModalPreview(true)"
    >
      <font-awesome-icon
        @click.stop="confirmImgRemoval"
        class="absolute"
        icon="window-close"
      />
      <img class="h100" ref="preview" alt="" />
    </figure>
    <div v-if="!isPreview">
      <label class="flex-center pointer" :cy="name" :for="name || id">
        <font-awesome-icon class="absolute" icon="file-import" />
      </label>
      <input
        type="file"
        :id="name"
        :name="name"
        :placeholder="placeholder"
        @change="handleInput($event)"
      />
    </div>
  </validation-provider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  export default {
    name: 'BaseFileInput',
    components: {
      ValidationProvider
    },
    props: {
      name: String,
      label: String,
      placeholder: String,
      type: String,
      rules: {
        type: String,
        default: ''
      },
      id: String,
      src: String,
      value: String,
      imgPreviewLabel: String
    },
    data () {
      return {
        acceptedFiles: [
          '.jpg',
          '.JPG',
          '.jpeg',
          '.png',
          '.bmp',
          '.gif',
          '.jfif',
          '.JFIF'
        ],
        imgName: undefined,
        isPreview: false,
        data: '',
        isConfirmRemoveImg: false,
        isImgModalPreview: false,
        file: undefined
      };
    },
    watch: {
      src: async function (newVal, oldVal) {
        this.isPreview = true;
        this.$nextTick(() => {
          this.$refs.preview.src = `/imgs/${newVal}`;
        });
        await this.$refs[this.name].validate(newVal);
      }
    },
    methods: {
      async handleInput (ev) {
        const valid = await this.$refs[this.name].validate(ev.target.files);
        this.file = ev.target.files[0];
        const re = /\.[0-9a-z]+$/i;
        const extension = this.file ? re.exec(this.file.name)[0] : '';
        if (this.acceptedFiles.includes(extension)) {
          this.imgName = `${this.label}${extension}`;
          this.isPreview = true;
          this.previewUploadedImage(this.file, extension);
          this.$emit('handle-file', {
            type: 'file',
            name: this.name,
            value: this.file
          });
        } else {
          alert('Format fisier nesuportat.');
        }
      },
      largePreviewUploadedImage (file) {
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs.largePreview.src = reader.result;
        };
        reader.readAsDataURL(file);
      },
      previewUploadedImage (file) {
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs.preview.src = reader.result;
        };
        reader.readAsDataURL(file);
      },
      confirmImgRemoval () {
        this.isConfirmRemoveImg = true;
      },
      cancelRemove (ev) {
        ev.preventDefault();
        this.isConfirmRemoveImg = false;
      },
      async removeImage (ev) {
        ev.preventDefault();
        this.$emit('remove-file', { name: this.name });
        this.$refs.preview.removeAttribute('src');
        this.$nextTick(async () => {
          await this.$refs[this.name].validate(undefined);
        });
        this.isPreview = false;
        this.isConfirmRemoveImg = false;
      },
      imgModalPreview (val) {
        this.isImgModalPreview = val;
        this.file && val && this.largePreviewUploadedImage(this.file);
      }
    }
  };
</script>

<style lang="scss">
  .base-file-input {
    label,
    & > figure {
      width: 100px;
      height: 84px;
    }
    & > figure {
      overflow: hidden;
      border-radius: 4px;
      border: 1px solid gray;
    }
    & > figure img {
      max-width: inherit;
    }
    & > figure svg {
      right: 6px;
      top: 6px;
      height: 16px;
      transform: scale(1.6);
      cursor: pointer;
      background-color: white;
      padding: 0 1px;
      border-radius: 2px;
      &:hover {
        transform: scale(1.8);
      }
    }
    label {
      background-color: lightgrey;
      border-radius: 4px;
      svg {
        transform: scale(1.2);
      }
    }
    input {
      position: absolute;
      width: 0.1px;
      height: 0.1px;
      visibility: hidden;
    }
    .img-preview {
      span {
        font-size: 1.2rem;
        padding-left: 12px;
        padding-top: 8px;
        background-color: white;
        display: block;
      }
      svg {
        transform: scale(2);
        top: -28px;
        right: -28px;
        path {
          fill: white;
        }
      }
      img {
        height: 500px;
        border: 12px solid white;
      }
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
</style>
