<template>
  <validation-provider
    class="base-textarea relative"
    :class="{ required: rules && rules.includes('required') }"
    :rules="rules"
    v-slot="{ errors }"
  >
    <label :for="name">{{ label || name }}</label>
    <textarea
      :type="type || 'text'"
      class="input w100"
      :cy="name"
      :id="name"
      :name="name"
      v-model="data"
      @input="input"
      :placeholder="placeholder || label"
      :value="value"
    />
    <span class="has-text-danger" v-if="errors[0]">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  export default {
    name: 'BaseInputText',
    components: {
      ValidationProvider
    },
    props: {
      name: String,
      label: String,
      placeholder: String,
      type: String,
      rules: String,
      value: String
    },
    data () {
      return {
        data: ''
      };
    },
    created () {
      this.value && (this.data = this.value);
    },
    methods: {
      input ({ target: { value } }) {
        this.$emit('input', { name: this.name, value });
      }
    },
    watch: {
      value: function (val) {
        this.data = val;
      }
    }
  };
</script>

<style lang="scss">
  .base-textarea {
    display: block;
    label,
    textarea {
      display: block;
    }
    label {
      margin-bottom: 4px;
    }
    textarea {
      height: 120px;
      //resize: none;
    }
    textarea + span {
      margin-top: 4px;
      display: block;
    }
  }
</style>
