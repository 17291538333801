import Vue from 'vue';
import Vuex from 'vuex';
import * as auth from './modules/auth';
import * as objects from './modules/objects';
import * as object from './modules/object';
import * as accounts from './modules/accounts';
import * as ansamblu from './modules/ansamblu';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isModal: false,
    isFetchingData: false
  },
  mutations: {
    SET_STATE (state, { module, key, value }) {
      module ? Vue.set(state[module], key, value) : Vue.set(state, key, value);
    },
    FETCHING_DATA (state, value) {
      Vue.set(state, 'isFetchingData', value);
    }
  },
  actions: {},
  modules: {
    auth,
    objects,
    object,
    accounts,
    ansamblu
  }
});
