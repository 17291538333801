<template>
  <div id="app">
    <font-awesome-icon
      v-if="$router.currentRoute.name !== 'Login'"
      cy="log-out-icon"
      class="icon-group sign-out-icon pointer fixed z99"
      @click="$store.dispatch('logout')"
      icon="sign-out-alt"
    />
    <router-link
        v-if="isArchivist"
        to="/admin">
      <font-awesome-icon
        v-if="$router.currentRoute.name === 'Objects'"
        cy="admin-icon"
        class="icon-group admin-icon pointer fixed z99"
        icon="user-cog"
      />
    </router-link>
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',
    computed: {
      isArchivist () {
        return this.$store.state.auth.user?.role === 'archivist'
      }
    }
  };
</script>

<style lang="scss">
  #app {
    .icon-group {
      top: 10px;
    }
    .sign-out-icon {
      right: 20px;
      opacity: 0.8;
      transform: scale(1.4);
    }
    .admin-icon {
      right: 50px;
      transform: scale(1.2);
    }
  }
</style>
