import axios from 'axios';
import api from '../api';
import router from '@/router';
import Vue from 'vue';

export const state = {
  objects: [],
  object: {},
  totalPages: undefined,
  totalObjects: undefined,
  objectsCount: [],
  procesRestaurare: ['Nerestaurat', 'Urgent', 'În curs', 'Finalizat'],
  echipa: [
    'Radu Dumitriu',
    'Adina Dumitriu',
    'Georgiana Vlahbei',
    'Maria Cernătescu',
    'Oana Perju',
    'Petronela Fotea'
  ],
  provenienta: [
    'Transilvania',
    'Maramureș',
    'Muntenia',
    'Moldova',
    'Dobrogea',
    'Banat',
    'Oltenia',
    'Bucovina'
  ],
  etnie: [
    'ROMÂNI',
    'GERMANI',
    'MAGHIARI',
    'EVREI',
    'BULGARI',
    'RROMI',
    'AROMÂNI',
    'UCRAINENI',
    'TURCI',
    'TĂTARI',
    'RUȘI-LIPOVENI',
    'CEHI',
    'GRECI',
    'MACEDONENI',
    'ALTĂ ETNIE/NECUNOSCUT'
  ],
  etichete: [
    'LEMN',
    'METAL',
    'STICLĂ',
    'PIELE',
    'TEXTIL',
    'CERAMICĂ',
    'PIATRĂ'
  ],
  obiecteAnsamblu: []
};

export const actions = {
  async delete_object_ansamblu ({ commit }, {idObject, ansambluId}) {
    try {
      commit('FETCHING_DATA', true);
      const url = `${api.deleteObjectAnsamblu}`;
      const { data } = await axios.post(url, {idObject, ansambluId});
      commit('FETCHING_DATA', false);
      commit('DELETE_OBIECT_ANSAMBLU', idObject)
    } catch (err) {
      commit('FETCHING_DATA', false);
      window.alert('Eroare stergere.');
    }
  },
  async get_obiecte_ansamblu ({ commit }, id) {
    try {
      const url = `${api.ansamblu}/obiecte-in-ansamblu/${id}`;
      const { data } = await axios(url);
      commit('SET_OBJECTS_STATE', { key: 'obiecteAnsamblu', value: data });
    } catch (err) {
      console.log(err);
    }
  },
  async link_object_to_ansamblu ({ state, commit }, ansamblu) {
    try {
      const url = `${api.object}/link-to-ansamblu`;
      await axios.post(url, { idObject: state.object._id, ansamblu });
      commit('LINK_OBJECT_TO_ANSAMBLU', ansamblu);
      commit('SET_STATE', { key: 'isModal', value: false });
    } catch (err) {
      console.log(err);
    }
  },
  async search_objects ({ commit }, searchedWord) {
    try {
      commit('FETCHING_DATA', true);
      commit('SET_STATE', {
        module: 'objects',
        key: 'objects',
        value: []
      });
      const url = `${api.objects}?search=${searchedWord}`;
      const { data } = await axios.get(url);
      commit('SET_OBJECTS', data);
      commit('FETCHING_DATA', false);
    } catch (err) {
      commit('FETCHING_DATA', true);
      window.alert('Eroare server.');
    }
  },
  async delete_object ({ commit }, id) {
    try {
      commit('FETCHING_DATA', true);
      const { data } = await axios.delete(`${api.object}/${id}`);
      commit('FETCHING_DATA', false);
      await router.push('/objects');
    } catch (err) {
      commit('FETCHING_DATA', false);
      window.alert('Eroare stergere fisa.');
    }
  },
  async verify_new_object_creation ({ commit }) {
    try {
      const { status } = await axios(`${api.object}/validate-new`);
      status === 200 &&
        commit('SET_STATE', {
          key: 'isModal',
          value: true
        });
    } catch (err) {
      alert('Cannot create new object. Already a new one in the table.');
    }
  },
  async get_objects ({ commit }, query = {}) {
    /* building the url query params */
    let queryString = '';
    const queryArr = Object.keys(query);
    queryArr.forEach((key, index) => {
      queryString += `${index === 0 ? '?' : ''}${key}=${query[key]}${
        index + 1 < queryArr.length ? '&' : ''
      }`;
    });
    try {
      commit('SET_STATE', {
        module: 'objects',
        key: 'objects',
        value: []
      });
      commit('FETCHING_DATA', true);
      const url = `${api.objects}${queryString}`;
      const { data } = await axios.get(url);
      commit('SET_OBJECTS', data);
      commit('FETCHING_DATA', false);
    } catch (err) {
      commit('FETCHING_DATA', false);
      alert(err);
    }
  },
  async create_object ({ state, commit }, formData) {
    try {
      const { data } = await axios.post(`${api.objects}/save`, formData);
      commit('UPDATE_OBJECTS', data);
    } catch (err) {
      console.log(err);
    }
  },
  async generate_object ({ commit }, type) {
    try {
      const { data } = await axios.post(`${api.object}/generate-new-object`, {
        type
      });
      console.log(data)
      commit('GENERATE_OBJECT', data);
      commit('SET_STATE', { key: 'isModal', value: false });
    } catch (err) {
      console.log(err);
    }
  },
  async update_object ({ commit }, { id, formData }) {
    try {
      commit('FETCHING_DATA', true);
      const { data } = await axios.put(`${api.object}/edit/${id}`, formData, {
        maxContentLength: 52428890
      });
      commit('UPDATE_OBJECT', data);
      commit('FETCHING_DATA', false);
      await router.push('/objects');
    } catch (err) {
      commit('FETCHING_DATA', false);
      console.log(err);
    }
  },
  async get_object ({ state, commit }, id) {
    try {
      const { data } = await axios.get(`${api.object}/${id}`);
      commit('SET_OBJECT', data);
    } catch (err) {
      console.log(err);
    }
  }
};

export const mutations = {
  DELETE_OBIECT_ANSAMBLU (state, idObject) {
    const objects = state.obiecteAnsamblu.filter(o => o.id !== idObject)
    Vue.set(state, 'obiecteAnsamblu', objects)
  },  
  SET_OBJECTS_STATE (state, { key, value }) {
    Vue.set(state, key, value);
  },
  LINK_OBJECT_TO_ANSAMBLU (state, ansamblu) {
    Vue.set(state, 'object', { ...state.object, ansamblu });
  },
  UPDATE_OBJECTS (state, payload) {
    state.objects.push(payload);
  },
  UPDATE_OBJECT (state, object) {
    let objectIndex;
    state.objects.forEach((obj, idx) => {
      obj.id === object.id && (objectIndex = idx);
    });
    Vue.set(state.objects, objectIndex, object);
  },
  GENERATE_OBJECT (state, payload) {
    state.objects.unshift(payload);
  },
  SET_OBJECT (state, object) {
    Vue.set(state, 'object', object);
  },
  SET_OBJECTS (state, { results, pages, total, objectsCount }) {
    Vue.set(state, 'objects', results);
    Vue.set(state, 'totalPages', pages);
    Vue.set(state, 'totalObjects', total);
    Vue.set(state, 'objectsCount', objectsCount);
  },
  UN_DELETE_OBJECT (state) {
    Vue.set(state, 'object', { ...state.object, deleted: false });
  }
};
